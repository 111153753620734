body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Ubuntu';
}

a {
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

button:not(:disabled) {
  cursor: pointer;
}
